import { ComponentProps, useId } from "react";
import Image from "next/image";
import {
  AlignItems,
  Box,
  Color,
  FontWeight,
  Glyph,
  Interpose,
  JustifyContent,
  Menu,
  MenuDensity,
  MenuRole,
  Space,
  Text,
  useTheme,
} from "@gocardless/flux-react";
import { ToTranslate } from "src/components/i18n";
import { Route } from "src/components/routing";
import parentOrgIllustration from "src/assets/svg/parent-org.svg";
import childOrgIllustration from "src/assets/svg/child-org.svg";
import { ListOrganisation } from "@gocardless/api/dashboard/types";

import { switcherMenuStyle } from "./OrganisationSwitcher.style";
import { OrganisationSwitcher } from "./OrganisationSwitcher";
import { OrganisationMenuItem } from "./menu-items/OrganisationMenuItem";
import { GeneralMenuItem } from "./menu-items/GeneralMenuItem";
import { useOrgSwitcherData } from "./hooks/useOrgSwitcherData";
import { LoggedInTag } from "./tags/LoggedInTag";

const generalMenuItems: {
  icon: Glyph;
  title: React.ReactNode;
  route: Route;
  disabled?: boolean;
}[] = [
  {
    icon: Glyph.Account,
    title: <ToTranslate>View all accounts</ToTranslate>,
    route: Route.Accounts,
  },
  {
    icon: Glyph.User,
    title: <ToTranslate>Manage teams</ToTranslate>,
    route: Route.Team,
    disabled: true,
  },
  {
    icon: Glyph.Add,
    title: <ToTranslate>Create account</ToTranslate>,
    route: Route.Accounts, // TODO: change this when page is ready
    disabled: true,
  },
];

interface OrganisationSwitcherMenuProps
  extends ComponentProps<typeof OrganisationSwitcher> {
  currentOrg?: ListOrganisation;
  organisations: ListOrganisation[];
  isMobile?: boolean;
}

export const OrganisationSwitcherMenu: React.FC<
  OrganisationSwitcherMenuProps
> = ({
  currentOrg,
  organisations,
  isMobile = false,
  useCustomHoverStyle = false,
}) => {
  const { parentOrganisation } = useOrgSwitcherData();

  const menuId = useId();
  const { theme } = useTheme();

  const isLoggedInOrgParent = parentOrganisation?.id === currentOrg?.id;

  return (
    <Menu.Root
      id={menuId}
      role={MenuRole.Menu}
      density={MenuDensity.Compact}
      css={switcherMenuStyle(theme)}
    >
      <Menu.Group title={null} description={null}>
        <Box
          layout="flex"
          alignItems={AlignItems.Center}
          justifyContent={JustifyContent.SpaceBetween}
        >
          <Box layout="flex" alignItems={AlignItems.Center}>
            <Image
              src={
                isLoggedInOrgParent
                  ? parentOrgIllustration
                  : childOrgIllustration
              }
              alt=""
              width={28}
              height={28}
            />
            <Space layout="inline" h={1} />
            <Box>
              <Text
                color={Color.Ultraviolet_1100}
                layout="block"
                size={2}
                weight={FontWeight.SemiBold}
              >
                {currentOrg?.nickname ?? currentOrg?.name}
              </Text>
              <Text color={Color.Ultraviolet_700} size={1}>
                {currentOrg?.id}
              </Text>
            </Box>
          </Box>
          <Space layout="inline" h={1} />
          <LoggedInTag />
        </Box>
      </Menu.Group>

      <Menu.Separator />

      <Menu.Group description={null} title={null}>
        <Interpose node={<Space v={1} />}>
          {organisations.map(({ id, nickname, name }) => (
            <OrganisationMenuItem
              key={id}
              id={id}
              nickname={nickname}
              name={name}
              isMobile={isMobile}
              useCustomHoverStyle={useCustomHoverStyle}
            />
          ))}
        </Interpose>
      </Menu.Group>

      <Menu.Separator />

      {generalMenuItems.map(({ icon, title, route, disabled }) => (
        <GeneralMenuItem
          key={icon}
          icon={icon}
          title={title}
          route={route}
          isMobile={isMobile}
          disabled={disabled}
          useCustomHoverStyle={useCustomHoverStyle}
        />
      ))}
    </Menu.Root>
  );
};
