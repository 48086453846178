import { ReactNode } from "react";
import { Box, XYGrid } from "@gocardless/flux-react";

import PageTitleBar from "./PageTitleBar";

export interface SplitPageLayoutProps {
  leftContent?: ReactNode;
  rightContent?: ReactNode;
}

const SplitPageLayout: React.FC<SplitPageLayoutProps> = ({
  leftContent,
  rightContent,
}) => (
  <XYGrid
    templateAreas={[
      `"top" "left" "right"`,
      null,
      null,
      `"top top"
         "left right"`,
    ]}
    templateColumns={["1fr", null, null, "1fr 1fr"]}
    templateRows={["max-content 170px  100vh", null, null, "max-content 100vh"]}
  >
    <Box gridArea="top">
      <PageTitleBar />
    </Box>
    <Box gridArea="left">{leftContent}</Box>
    <Box gridArea="right">{rightContent}</Box>
  </XYGrid>
);

export default SplitPageLayout;
