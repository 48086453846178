import { Color, CSSRulesFunction } from "@gocardless/flux-react";

export const switcherMenuStyle: CSSRulesFunction = (theme) => ({
  "li > div": { padding: 0 },
  "ul > span": { paddingBottom: 0 },
  "li[role='separator']": { margin: `${theme.spacing(1)} 0` },
});

export const menuItemStyle: CSSRulesFunction<{
  isMobile: boolean;
  useCustomHoverStyle?: boolean;
}> = (theme, { isMobile, useCustomHoverStyle = false }) => ({
  borderLeft: "none",
  padding: `${theme.spacing(0.5)} 0`,
  ...(useCustomHoverStyle && {
    "&:hover": {
      textDecoration: "none",
      backgroundColor: theme.color(Color.Greystone_100),
      width: `calc(100% + ${theme.spacing(3)})`,
      marginLeft: `-${theme.spacing(1.5)}`,
      div: {
        marginLeft: theme.spacing(1.5),
      },
      "div > div": {
        marginLeft: 0,
      },
    },
  }),
  "svg + span": { marginLeft: isMobile ? 20 : 8 },
});

export const marqueeStyle: CSSRulesFunction<{
  overflowWidth: number;
  maxContentWidth: number;
}> = (_, { maxContentWidth, overflowWidth }) => ({
  display: "inline-block",
  maxWidth: maxContentWidth,
  overflow: "hidden",
  whiteSpace: "nowrap",

  "&:not(:hover, :focus)": {
    span: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },

  ...(overflowWidth > 0 && {
    "&:hover, &:focus": {
      span: {
        display: "inline-block",
        animation: `marquee-text calc(${
          overflowWidth * 0.05
        }s) linear forwards`,
      },
    },
  }),

  "@keyframes marquee-text": {
    "0%": {
      transform: "translateX(0)",
    },

    "100%": {
      transform: `translateX(calc(-100% + ${maxContentWidth}px))`,
    },
  },
});
