import { useLingui } from "@lingui/react";
import { CountryCodes } from "src/common/country";
import { countryToCurrency, Currency } from "src/common/currencies";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { usePrimaryCreditor } from "src/queries/creditor";

export function useReferralAmount() {
  const creditor = usePrimaryCreditor();

  return useReferralAmountByGeo(creditor?.geo ?? "");
}

export function useReferralAmountByGeo(geo: string | null) {
  const { i18n } = useLingui();
  const { isVariationOn: isDoubleRewardsPromotionEnabled } =
    useOptimizelyVariation({
      flag: OptimizelyFlag.SPARK_DOUBLE_REWARDS_PROMOTION,
    });

  if (!geo) return { incentive: null, threshold: null };

  const currency = countryToCurrency(geo as CountryCodes);

  const currencyToIncentive: Record<Currency, number> = {
    [Currency.Aud]: 200,
    [Currency.Cad]: 200,
    [Currency.Dkk]: 1000,
    [Currency.Eur]: 150,
    [Currency.Gbp]: 100,
    [Currency.Nzd]: 200,
    [Currency.Sek]: 1500,
    [Currency.Usd]: 150,
  };

  const currencyToThreshold: Record<Currency, number> = {
    [Currency.Aud]: 1000,
    [Currency.Cad]: 850,
    [Currency.Dkk]: 4500,
    [Currency.Eur]: 600,
    [Currency.Gbp]: 500,
    [Currency.Nzd]: 1000,
    [Currency.Sek]: 7000,
    [Currency.Usd]: 700,
  };

  const format = {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
    currencyDisplay: "narrowSymbol",
  };

  const incentiveAmountForLocale = currencyToIncentive[currency];

  const incentiveAmount = isDoubleRewardsPromotionEnabled
    ? incentiveAmountForLocale * 2
    : incentiveAmountForLocale;

  const incentive = i18n.number(incentiveAmount, format);
  const threshold = i18n.number(currencyToThreshold[currency], format);

  return { incentive, threshold };
}
