/* eslint-disable-next-line no-restricted-imports */
import {
  PlainButton,
  HoverEffect,
  AlignItems,
  Box,
  FontWeight,
  Icon,
  Text,
  Space,
  IconButton,
  ButtonVariant,
  Glyph,
} from "@gocardless/flux-react";
import { useEffect } from "react";
import { Trans } from "@lingui/macro";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";

import { isSearchPage } from "./utils";

import { SearchForm } from ".";

interface SearchFormContainerProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isMobile?: boolean;
}

const SearchFormContainer: React.FC<SearchFormContainerProps> = ({
  isOpen,
  setOpen,
  isMobile,
}) => {
  const router = useRouter();
  const queryText = router.query?.query;

  const inSearchPage = isSearchPage(router);

  const form = useForm({
    values: {
      search: queryText as string,
    },
  });

  useEffect(() => {
    if (inSearchPage && queryText) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryText]);

  const toggleSearchForm = () => setOpen((prevState) => !prevState);

  return (
    <>
      {!isOpen &&
        (!isMobile ? (
          <PlainButton
            onClick={toggleSearchForm}
            effect={HoverEffect.TextDecoration}
          >
            <Box layout="flex" alignItems={AlignItems.Center}>
              <Icon name={Glyph.Search} size="12px" />
              <Space h={0.5} />
              <Text weight={FontWeight.SemiBold}>
                <Trans id="Search">Search</Trans>
              </Text>
            </Box>
          </PlainButton>
        ) : (
          <IconButton
            onClick={toggleSearchForm}
            label={<Trans id="Open search input">Open search input</Trans>}
            icon={Glyph.Search}
            variant={ButtonVariant.TextOnLight}
          />
        ))}
      {isOpen && (
        <SearchForm
          form={form}
          onBlur={() => setOpen(false)}
          setOpen={setOpen}
        />
      )}
    </>
  );
};

export default SearchFormContainer;
