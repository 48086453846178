import { useOrganisation } from "src/queries/organisation";
import { useCapitalIntegrationList } from "@gocardless/api/dashboard/capital-integration";
import { useUserPermissions } from "src/common/hooks/user-permissions/useUserPermissions";

export const INTEGRATION_NAME = "pipe";

export const useIsEligibleForPipe = () => {
  const organisation = useOrganisation();
  const userPermissions = useUserPermissions();
  const userIsAdmin = !!userPermissions.isAdmin;

  return !!organisation?.pipe_amount && userIsAdmin;
};

export const usePipeStatus = ({
  shouldMakeRequest,
}: {
  shouldMakeRequest?: boolean;
}) => {
  const { data: capitalIntegrationsResponse, ...apiResponse } =
    useCapitalIntegrationList(shouldMakeRequest);
  const pipeStatus = capitalIntegrationsResponse?.capital_integrations?.find(
    (capitalIntegration) =>
      capitalIntegration?.integration_name === INTEGRATION_NAME
  );

  return {
    pipeStatus,
    ...apiResponse,
  };
};
