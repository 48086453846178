import {
  Box,
  ButtonSize,
  ButtonVariant,
  Color,
  ColorScheme,
  FontWeight,
  Glyph,
  IconButton,
  P,
  Space,
  Tag,
  TagSize,
  TagVariant,
  TypeScale,
  useTheme,
} from "@gocardless/flux-react";
import { useLocalStorage } from "src/hooks/useLocalStorage/useLocalStorage";
import { i18n } from "@lingui/core";
import {
  ReferralsInformationClickedTarget,
  ReferralsSource,
} from "src/common/trackingEventAttributes";
import { TrackingEvent } from "src/common/trackingEvents";
import { Trans, t } from "@lingui/macro";
import { useSegment } from "src/technical-integrations/segment/useSegment";

import { LinkButton } from "../shared/LinkButton";
import { Route } from "../routing";
import { useReferralAmount } from "../routes/Refer/hooks/useReferralAmount";

const DASHBOARD_REFERRAL_PROMO_DISMISSED =
  "gc.dashboard.referral.promo.dismissed";

export const DoubleRewards = () => {
  const referralAmount = useReferralAmount();
  const { theme } = useTheme();
  const [isDismissed, setIsDismissed] = useLocalStorage(
    DASHBOARD_REFERRAL_PROMO_DISMISSED,
    String(false)
  );

  const { sendEvent } = useSegment();

  const isPromoDismissed = JSON.parse(isDismissed);

  const handleRefer = () => {
    sendEvent(TrackingEvent.DASHBOARD_REFER_CTA_CLICKED, {
      target: ReferralsInformationClickedTarget.REFER_NOW,
      source: ReferralsSource.DOUBLE_REWARD,
    });
  };

  const handleDismiss = () => {
    setIsDismissed(String(true));
    sendEvent(TrackingEvent.DASHBOARD_REFER_POPUP_DISMISSED, {
      source: ReferralsSource.DOUBLE_REWARD,
    });
  };

  if (isPromoDismissed) return null;

  return (
    <Box
      bg={Color.Ultraviolet_50}
      spaceAbove={1.5}
      spaceBefore={1.5}
      borderRadius={1}
      spaceAfter={1.5}
      spaceBelow={1.5}
      gutterH={1}
      gutterV={1.5}
      data-testid="double-rewards-sidebar"
      css={{
        position: "relative",
      }}
    >
      <Box
        css={{
          position: "absolute",
          right: "0",
          top: "0",
        }}
      >
        <IconButton
          label={i18n._("Dismiss")}
          variant={ButtonVariant.TextOnLight}
          size={ButtonSize.Sm}
          icon={Glyph.Close}
          onClick={handleDismiss}
        />
      </Box>
      <Tag
        size={[TagSize.Md, null, null, null, TagSize.Lg]}
        variant={TagVariant.Solid}
        colorScheme={ColorScheme.OnLight}
        css={{
          background: theme.tokens.colors[Color.Ultraviolet_700],
        }}
      >
        <Trans id="double-rewards-referral.double-rewards">
          Double rewards
        </Trans>
      </Tag>
      <Space v={0.75} />
      <P
        color={Color.Greystone_DarkMatter}
        size={TypeScale.Size_08}
        fontFamily="display"
        weight={FontWeight.Bold}
      >
        <Trans id="double-rewards-referral.sidebar-text">
          Refer and get {referralAmount.incentive} each
        </Trans>
      </P>
      <Space v={1} />
      <LinkButton
        label={t({
          id: "double-rewards-referral.sidebar-button-label",
          message: "Refer now",
        })}
        route={Route.Refer}
        onClick={handleRefer}
      />
    </Box>
  );
};
