import { useEffect } from "react";

export const useBodyOverflowForPopups = ({
  isPopupOpen,
}: {
  isPopupOpen: boolean;
}) => {
  useEffect(() => {
    if (isPopupOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "revert";
    }

    return () => {
      document.body.style.overflow = "revert";
    };
  }, [isPopupOpen]);
};
