import {
  ResponsiveValue,
  Sidebar as SidebarComponent,
  SidebarLayout,
  ColorScheme,
  Box,
} from "@gocardless/flux-react";

import { Route } from "../../common/routing/routes";

import Navigation from "./Navigation";

export interface SidebarProps {
  highlightedMenuLink?: Route;
  width: ResponsiveValue<number | string>;
  height?: ResponsiveValue<number | string>;
}

const Sidebar: React.FC<SidebarProps> = (props) => {
  const { highlightedMenuLink: highlightedRoute, height } = props;

  return (
    <Box css={{ position: "fixed" }} height={height}>
      <SidebarComponent
        open
        colorScheme={ColorScheme.OnDark}
        layout={SidebarLayout.StaticLeft}
        contentWidth={[
          "248px", // intention is to use floating sidebars here
          "264px", // and here
          null, // and here
          "240px",
          "264px",
        ]}
      >
        <Navigation highlightedMenuLink={highlightedRoute} height={height} />
      </SidebarComponent>
    </Box>
  );
};

export default Sidebar;
