import { useTheme, Breakpoint } from "@gocardless/flux-react";
import { useWindowSize } from "react-use";

interface IsBreakingPointHookConfig {
  min?: Breakpoint;
  max?: Breakpoint;
}
export const useIsBreakpoint = ({ min, max }: IsBreakingPointHookConfig) => {
  const { theme } = useTheme();
  const { width } = useWindowSize();
  const maxBreakPoint = max
    ? parseInt(theme.tokens.breakpoints[max], 10)
    : Infinity;
  const minBreakPoint = min ? parseInt(theme.tokens.breakpoints[min], 10) : 0;

  return width < maxBreakPoint && width >= minBreakPoint;
};
