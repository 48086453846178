import { CreditorResource } from "@gocardless/api/dashboard/types";

import { CountryCodes, CountryCodeSupportedBankAccountData } from "../country";

/**
 * Generates a custom hook that checks if the provided creditor is supported by Bank Account Data.
 *
 * @param {CreditorResource | null} creditor - The creditor to check for support.
 * @return An object containing the list of supported countries and a function that checks if the creditor is supported.
 */
export const useSupportedBankAccountDataCreditor = (
  creditor?: CreditorResource | null
) => {
  const isCreditorSupported = () =>
    !!creditor?.geo &&
    CountryCodeSupportedBankAccountData.includes(creditor.geo as CountryCodes);

  return { isCreditorSupported };
};
