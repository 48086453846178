import Image from "next/image";
import { ListOrganisation } from "@gocardless/api/dashboard/types";
import {
  AlignItems,
  Box,
  ColorPreset,
  FontWeight,
  Menu,
  Space,
  Text,
  useTheme,
} from "@gocardless/flux-react";
import childOrgIllustration from "src/assets/svg/child-org.svg";
import parentOrgIllustration from "src/assets/svg/parent-org.svg";
import { useSwitchOrganisation } from "src/common/hooks/useSwitchOrganisation";

import { menuItemStyle } from "../OrganisationSwitcher.style";
import { useOrgSwitcherData } from "../hooks/useOrgSwitcherData";

export const OrganisationMenuItem: React.FC<
  ListOrganisation & { isMobile: boolean; useCustomHoverStyle?: boolean }
> = ({ id, nickname, name, isMobile, useCustomHoverStyle = false }) => {
  const { parentOrganisation } = useOrgSwitcherData();
  const { submit } = useSwitchOrganisation({ organisationId: id });

  const { theme } = useTheme();

  const isParentOrg = parentOrganisation?.id === id;

  return (
    <Menu.Button
      css={menuItemStyle(theme, { isMobile, useCustomHoverStyle })}
      onClick={() => submit(id)}
    >
      <Box layout="flex" alignItems={AlignItems.Center}>
        <Image
          src={isParentOrg ? parentOrgIllustration : childOrgIllustration}
          alt=""
          height={28}
          width={28}
        />
        <Space h={1} layout="inline" />
        <Box>
          <Text layout="block" size={2} weight={FontWeight.SemiBold}>
            {nickname ?? name}
          </Text>
          <Text color={ColorPreset.TextOnLight_03} size={1}>
            {id}
          </Text>
        </Box>
      </Box>
    </Menu.Button>
  );
};
