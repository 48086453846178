import { useState } from "react";

const useToggle = (initialState = false) => {
  const [isOn, setIsOn] = useState(initialState);

  const toggle = () => setIsOn((prevState) => !prevState);

  const on = () => setIsOn(true);

  const off = () => setIsOn(false);

  return {
    isOn,
    toggle,
    on,
    off,
  };
};

export default useToggle;
