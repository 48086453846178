import { forwardRef } from "react";
import { Box, ColorPreset, useTheme, ZIndex } from "@gocardless/flux-react";
import { useBodyOverflowForPopups } from "src/common/hooks/useBodyOverflowForPopups";

interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Drawer = forwardRef<HTMLDivElement, DrawerProps>(
  ({ isOpen, children }, ref) => {
    useBodyOverflowForPopups({ isPopupOpen: isOpen });

    const { theme } = useTheme();

    return (
      <>
        <Box
          aria-hidden
          css={{
            position: "fixed",
            top: 0,
            left: 0,
            transition: "opacity .3s ease",
          }}
          bg={ColorPreset.IconOnDark_01}
          height={isOpen ? "100%" : 0}
          opacity={isOpen ? 0.4 : 0}
          width="100%"
        />

        <Box
          ref={ref}
          bg={ColorPreset.BackgroundLight_01}
          borderWidth={1}
          borderColor={ColorPreset.BorderOnLight_04}
          borderRadius={1.5}
          gutterH={1.5}
          gutterV={1.5}
          width="100%"
          maxWidth="640px"
          css={{
            bottom: 0,
            left: "50%",
            paddingBottom: theme.spacing(2),
            position: "fixed",
            transform: `translate(-50%, ${isOpen ? "5%" : "100%"})`,
            transition: "transform .4s ease",
            zIndex: ZIndex.Dialog,
          }}
        >
          {children}
        </Box>
      </>
    );
  }
);

export default Drawer;
