import { default as NewSiteSettingsDropdown } from "./SiteSettingsDropdown";
import { default as NewSiteSettingsDialog } from "./SiteSettingsDialog";
import { default as NewSiteSettingsMenu } from "./SiteSettingsMenu";

export const SiteSettingsDropdown = () => <NewSiteSettingsDropdown />;

export const SiteSettingsDialog = () => <NewSiteSettingsDialog />;

export const SiteSettingsMenu = ({ isDropDown = false }) => (
  <NewSiteSettingsMenu isDropdown={isDropDown} />
);
