import { useEffect, useId, useRef } from "react";
import Image from "next/image";
import {
  AlignItems,
  Box,
  Breakpoint,
  ColorPreset,
  Dropdown,
  DropdownTriggerProps,
  FontWeight,
  Glyph,
  Icon,
  PlainButton,
  Space,
  Text,
  useTheme,
} from "@gocardless/flux-react";
import { i18nMarkTranslate } from "src/components/i18n";
/* eslint-disable import/no-unresolved */
import gocardlessLogo from "src/assets/svg/gc-logo-square.svg";
import useToggle from "src/hooks/useToggle";
import { useIsBreakpoint } from "src/hooks/useIsBreakpoint";

import Drawer from "./Drawer";
import { marqueeStyle } from "./OrganisationSwitcher.style";
import { OrganisationSwitcherMenu } from "./OrganisationSwitcherMenu";
import { useOrgSwitcherData } from "./hooks/useOrgSwitcherData";

interface OrganisationSwitcherProps {
  // Currently used for Storybook only
  useCustomHoverStyle?: boolean;
  maxContentWidth?: number;
}

interface TriggerButtonProps {
  name?: string;
  dropdownTriggerProps?: DropdownTriggerProps;
  handleClick?: () => void;
  maxContentWidth?: number;
}

const TriggerButton: React.FC<TriggerButtonProps> = ({
  name,
  dropdownTriggerProps,
  handleClick,
  maxContentWidth = 125,
}) => {
  const { theme } = useTheme();

  const isBelowXlBreakpoint = useIsBreakpoint({ max: Breakpoint.Lg });

  const marqueeTextRef = useRef<HTMLSpanElement>(null);

  const overflowWidth = marqueeTextRef.current?.clientWidth
    ? marqueeTextRef.current.scrollWidth - marqueeTextRef.current.offsetWidth
    : 0;

  return (
    <PlainButton
      {...dropdownTriggerProps}
      {...(handleClick && { onClick: handleClick })}
      css={{
        ":hover": {
          "svg, span": {
            color: theme.color(
              isBelowXlBreakpoint
                ? ColorPreset.TextOnLight_02
                : ColorPreset.TextOnDark_02
            ),
          },
        },
      }}
    >
      <Box layout="flex" alignItems={AlignItems.Center}>
        <Image
          alt={i18nMarkTranslate("GoCardless logo")}
          src={gocardlessLogo}
          width={32}
          height={32}
        />
        <Space layout="inline" h={0.75} />
        <Box css={marqueeStyle(theme, { maxContentWidth, overflowWidth })}>
          <Text
            ref={marqueeTextRef}
            color={[
              ColorPreset.TextOnLight_01,
              null,
              null,
              ColorPreset.TextOnDark_01,
            ]}
            layout="block"
            size={3}
            weight={FontWeight.SemiBold}
          >
            {name}
          </Text>
        </Box>
        <Space layout="inline" h={0.5} />
        <Icon
          color={[
            ColorPreset.TextOnLight_01,
            null,
            null,
            ColorPreset.TextOnDark_01,
          ]}
          name={Glyph.ChevronDown}
          size="12px"
        />
      </Box>
    </PlainButton>
  );
};

export const OrganisationSwitcher: React.FC<OrganisationSwitcherProps> = ({
  useCustomHoverStyle = false,
  maxContentWidth = 125,
}) => {
  const { loggedInOrganisation, organisations } = useOrgSwitcherData();

  const dropdownId = useId();
  const { theme } = useTheme();

  return (
    <Dropdown
      css={{
        "& > div": {
          display: "flex",
        },
        "div[data-dropdown-open]": {
          maxWidth: "max-content",
          padding: `${theme.spacing(1.5)} ${theme.spacing(1.5)}`,
          left: 0,
        },
      }}
      controls={dropdownId}
      trigger={(props) => (
        <TriggerButton
          dropdownTriggerProps={props}
          name={loggedInOrganisation?.nickname ?? loggedInOrganisation?.name}
          maxContentWidth={maxContentWidth}
        />
      )}
    >
      <OrganisationSwitcherMenu
        currentOrg={loggedInOrganisation}
        organisations={organisations}
        useCustomHoverStyle={useCustomHoverStyle}
      />
    </Dropdown>
  );
};

export const MobileOrganisationSwitcher: React.FC<
  OrganisationSwitcherProps
> = ({ useCustomHoverStyle }) => {
  const {
    isOn: isDrawerOpen,
    on: openDrawer,
    off: closeDrawer,
  } = useToggle(false);

  const { organisations, loggedInOrganisation } = useOrgSwitcherData();

  const drawerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleMouse = (event: MouseEvent) => {
      if (!event.target || !(event.target instanceof Element)) {
        return;
      }

      if (
        isDrawerOpen &&
        drawerRef.current &&
        !drawerRef.current?.contains(event.target)
      ) {
        closeDrawer();
      }
    };

    const handleKeyboard = (event: KeyboardEvent) => {
      if (isDrawerOpen && event.key === "Escape") {
        closeDrawer();
      }
    };

    document.addEventListener("mouseup", handleMouse, true);
    document.addEventListener("keydown", handleKeyboard, true);

    return () => {
      document.removeEventListener("mouseup", handleMouse, true);
      document.removeEventListener("keydown", handleKeyboard, true);
    };
  }, [closeDrawer, isDrawerOpen]);

  return (
    <Box layout="flex">
      <TriggerButton
        name={loggedInOrganisation?.nickname ?? loggedInOrganisation?.name}
        handleClick={openDrawer}
      />
      <Drawer ref={drawerRef} isOpen={isDrawerOpen} onClose={closeDrawer}>
        <OrganisationSwitcherMenu
          isMobile
          currentOrg={loggedInOrganisation}
          organisations={organisations}
          useCustomHoverStyle={useCustomHoverStyle}
        />
      </Drawer>
    </Box>
  );
};
