import { ReactNode } from "react";
import {
  JustifyContent,
  Grid,
  Column,
  Container,
} from "@gocardless/flux-react";

import PageTitleBar from "./PageTitleBar";

export interface SecondaryPageLayoutProps {
  title?: string | ReactNode;
  children?: React.ReactNode;
}

const SecondaryPageLayout: React.FC<SecondaryPageLayoutProps> = ({
  title,
  children,
}) => (
  <>
    <PageTitleBar title={title} />
    <Container>
      <Grid justifyContent={JustifyContent.Center}>
        <Column size={[12, null, null, 6]}>{children}</Column>
      </Grid>
    </Container>
  </>
);

export default SecondaryPageLayout;
