import {
  Box,
  Color,
  HoverEffect,
  Overflow,
  PlainLink,
  TextWrap,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";

import { LinkBuilder, Route } from "../routing";

import { SearchSuggestion, searchResourceTypeToText } from "./utils";

export interface SearchSuggestionProps {
  setShowSuggestions: React.Dispatch<React.SetStateAction<boolean>>;
  showSuggestions: boolean;
  searchText?: string;
  suggestions?: Array<SearchSuggestion>;
}

const suggestionTextStyle = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "100%",
};

const SearchSuggestions: React.FC<SearchSuggestionProps> = ({
  setShowSuggestions,
  showSuggestions,
  searchText,
  suggestions,
}) => {
  const trimmedText = searchText?.trim();

  return showSuggestions && trimmedText ? (
    <Box
      borderRadius={0.5}
      overflowX={Overflow.Hidden}
      overflowY={Overflow.Hidden}
      bg={Color.White}
      elevation={2}
      spaceAbove={0.25}
      flexDirection="column"
      width="100%"
      gutterV={1}
      gutterH={1}
      css={{
        position: "absolute",
      }}
    >
      {suggestions &&
        suggestions.map((sgs) => (
          <LinkBuilder
            key={sgs.id}
            route={sgs.route}
            routeParams={{ id: sgs.id.toUpperCase() }}
          >
            {(result) => (
              <PlainLink
                {...result}
                onClick={() => {
                  setShowSuggestions(false);
                }}
                textWrap={TextWrap.NoWrap}
                effect={HoverEffect.TextDecoration}
                spaceBelow={0.5}
                css={{ display: "flex" }}
              >
                <Box gutterV={0.25} gutterH={0.25} css={suggestionTextStyle}>
                  {searchResourceTypeToText()[sgs.type]} {trimmedText}
                </Box>
              </PlainLink>
            )}
          </LinkBuilder>
        ))}
      <LinkBuilder route={Route.Search} queryParams={{ query: trimmedText }}>
        {(result) => (
          <PlainLink
            {...result}
            onClick={() => {
              setShowSuggestions(false);
            }}
            textWrap={TextWrap.NoWrap}
            effect={HoverEffect.TextDecoration}
            css={{ display: "flex" }}
          >
            <Box gutterV={0.25} gutterH={0.25} css={suggestionTextStyle}>
              <Trans id="search.for">Search for</Trans> {trimmedText}
            </Box>
          </PlainLink>
        )}
      </LinkBuilder>
    </Box>
  ) : null;
};

export default SearchSuggestions;
