import { FC } from "react";
import { ColorScheme, Menu, Tag, TagColor } from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { LinkBuilder, Route } from "src/components/routing";

import { useAccountSetup } from "../routes/SetupPayments/home/useAccountSetup";

interface GettingStartedMenuLinkProps {
  getAccentState: (route: Route) => "visible" | "hidden";
}

const GettingStartedMenuLink: FC<GettingStartedMenuLinkProps> = ({
  getAccentState,
}) => {
  const { value, max } = useAccountSetup();

  return (
    <LinkBuilder route={Route.Home}>
      {(result) => (
        <Menu.Link {...result} accent={getAccentState(Route.Home)}>
          {value === max ? (
            <Trans id="Home">Home</Trans>
          ) : (
            <>
              <Trans id="Home">Home</Trans>
              <Tag
                colorScheme={ColorScheme.OnDark}
                color={TagColor.Neutral}
                css={{ float: "right" }}
              >
                {value}/{max}
              </Tag>
            </>
          )}
        </Menu.Link>
      )}
    </LinkBuilder>
  );
};

export default GettingStartedMenuLink;
