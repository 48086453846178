import {
  AlignItems,
  Box,
  FontWeight,
  Glyph,
  Icon,
  Menu,
  Space,
  Text,
  useTheme,
} from "@gocardless/flux-react";
import { Route, routerPush } from "src/common/routing";

import { menuItemStyle } from "../OrganisationSwitcher.style";

export const GeneralMenuItem: React.FC<{
  icon: Glyph;
  title: React.ReactNode;
  route: Route;
  isMobile: boolean;
  disabled?: boolean;
  useCustomHoverStyle?: boolean;
}> = ({
  icon,
  title,
  isMobile,
  route,
  disabled = false,
  useCustomHoverStyle = false,
}) => {
  const { theme } = useTheme();

  return (
    <Menu.Button
      onClick={() => routerPush({ route })}
      css={menuItemStyle(theme, { isMobile, useCustomHoverStyle })}
      disabled={disabled}
    >
      <Box
        layout="flex"
        alignItems={AlignItems.Center}
        gutterH={icon === Glyph.Account ? 0.5 : 0.25}
      >
        <Icon size="12px" name={icon} />
        <Space h={isMobile ? 0.25 : 1} layout="inline" />
        <Text weight={FontWeight.SemiBold}>{title}</Text>
      </Box>
    </Menu.Button>
  );
};
