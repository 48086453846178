/* eslint-disable-next-line no-restricted-imports */
import {
  Glyph,
  Dropdown,
  PlainButton,
  Icon,
  Space,
  Box,
  AlignItems,
  Text,
  FontWeight,
  HoverEffect,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";

import { SiteSettingsMenu } from "./";

const SiteSettingsDropdown: React.FC = () => (
  <Dropdown
    data-testid="site-settings-dropdown"
    controls="site-settings"
    placement="top-start"
    maxWidth="600px"
    trigger={(props) => (
      <PlainButton {...props} effect={HoverEffect.TextDecoration}>
        <Box layout="flex" alignItems={AlignItems.Center}>
          <Icon name={Glyph.Settings} size="12px" />
          <Space h={0.5} />
          <Text weight={FontWeight.SemiBold}>
            <Trans id="Settings">Settings</Trans>
          </Text>
        </Box>
      </PlainButton>
    )}
  >
    <SiteSettingsMenu isDropDown={true} />
  </Dropdown>
);

export default SiteSettingsDropdown;
