import {
  ButtonVariant,
  IconButton,
  Glyph,
  Sidebar,
  SidebarLayout,
  ColorScheme,
} from "@gocardless/flux-react";
/* eslint-disable-next-line no-restricted-imports */
import { Trans } from "@lingui/macro";
import { useState } from "react";

import { Route } from "../routing";

import Navigation from "./Navigation";

const MobilePrimaryNavigation: React.FC<{
  highlightedMenuLink?: Route;
}> = (props) => {
  const [isOpen, setOpen] = useState(false);

  const toggleOpen = () => setOpen((prevState) => !prevState);

  return (
    <>
      <IconButton
        onClick={toggleOpen}
        variant={ButtonVariant.TextOnLight}
        icon={Glyph.Menu}
        label={
          <Trans id="Open main navigation sidebar">
            Open main navigation sidebar
          </Trans>
        }
      />
      <Sidebar
        open={isOpen}
        layout={SidebarLayout.FloatingLeft}
        colorScheme={ColorScheme.OnDark}
        closeAction={{
          label: <Trans id="Close">Close</Trans>,
          onClose: () => setOpen(false),
        }}
      >
        <Navigation highlightedMenuLink={props.highlightedMenuLink} />
      </Sidebar>
    </>
  );
};

export default MobilePrimaryNavigation;
