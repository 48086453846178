import { ReactNode } from "react";
import {
  Box,
  ColorPreset,
  FontWeight,
  JustifyContent,
  AlignItems,
  H1,
  Grid,
  Column,
  Container,
} from "@gocardless/flux-react";

import GoCardlessLogo from "../GoCardlessLogo";

export interface PageTitleBarProps {
  title?: ReactNode;
}

const PageTitleBar: React.FC<PageTitleBarProps> = ({ title }) => (
  <Box elevation={4}>
    <Box gutterV={1} bg={ColorPreset.BackgroundLight_01}>
      <Container maxContentWidth="100%">
        <Grid>
          <Column size={[0, null, 3]}>
            <GoCardlessLogo variant="dark" size="md" />
          </Column>
          <Column size={[12, null, 6]}>
            <Box
              layout="flex"
              justifyContent={JustifyContent.Center}
              alignItems={AlignItems.Center}
              width="100%"
            >
              {title && (
                <H1 size={3} weight={FontWeight.SemiBold}>
                  {title}
                </H1>
              )}
            </Box>
          </Column>
          <Column size={[0, null, 3]} />
        </Grid>
      </Container>
    </Box>
  </Box>
);

export default PageTitleBar;
