import { getConfig } from "src/common/config";

const formspreeForms = {
  dashboard: "dashboard",
  reporting: "reporting",
  planSchedulePause: "plan_schedule_pause",
} as const;

export type FormspreeForm =
  (typeof formspreeForms)[keyof typeof formspreeForms];

export const getFormspreeApiUrl = (name: FormspreeForm) => {
  const formId = getConfig().client.formSpree.ids?.[name] ?? "";

  return `https://formspree.io/f/${formId}`;
};
