import {
  ButtonLayout,
  ButtonSize,
  ButtonVariant,
  Link,
} from "@gocardless/flux-react";
import { getRouteURL, Route } from "src/common/routing";

interface LinkButtonProps {
  label: string;
  variant?: ButtonVariant;
  id?: string;
  route: Route;
  onClick?: () => void;
}

export const LinkButton: React.FC<LinkButtonProps> = ({
  label,
  variant = ButtonVariant.PrimaryOnLight,
  id,
  route,
  onClick,
}) => (
  <Link
    variant={variant}
    layout={ButtonLayout.Full}
    size={ButtonSize.Sm}
    href={getRouteURL({
      route,
      routeParams: id ? { id } : {},
    })}
    {...(onClick && { onClick })}
  >
    {label}
  </Link>
);
