// this is a duplicate of BodyPortal from flux
// https://github.com/gocardless/flux/blob/main/packages/flux-react/src/BodyPortal.tsx

import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

const BodyPortal: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [portalEl, setPortalEl] = useState<HTMLDivElement | null>();

  useEffect(() => {
    if (!portalEl) {
      const newPortalEl = document.createElement("div");
      document.body.appendChild(newPortalEl);
      setPortalEl(newPortalEl);
    }

    return () => {
      portalEl && document.body.removeChild(portalEl);
    };
  }, [portalEl]);

  return portalEl ? createPortal(children, portalEl) : null;
};

export default BodyPortal;
