import { useMemo } from "react";
import { useRestrictionList } from "@gocardless/api/dashboard/restriction";

export interface UseIsRestrictedReturns {
  isRestricted: boolean;
  isValidating: boolean;
}

interface Restrictions {
  [resource: string]: string;
}

const isRestricted = (
  restrictions: Restrictions,
  userRestrictions: { [resource: string]: string[] }
) => {
  for (const [resource, action] of Object.entries(restrictions)) {
    if (
      Object.prototype.hasOwnProperty.call(userRestrictions, resource) &&
      userRestrictions[resource]?.includes(action)
    ) {
      return true;
    }
  }
  return false;
};

export const useIsRestricted = (
  restrictions: Restrictions
): UseIsRestrictedReturns => {
  const userRestrictions = useRestrictionList();
  return {
    isRestricted: useMemo(
      () =>
        isRestricted(restrictions, userRestrictions.data?.restrictions || {}),
      [restrictions, userRestrictions.data]
    ),
    isValidating: userRestrictions.isValidating,
  };
};
