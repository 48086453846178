import { useEffect, useRef, useState } from "react";
import {
  Input,
  Icon,
  ColorPreset,
  Glyph,
  Form,
  InputGutter,
  Box,
  PlainButton,
} from "@gocardless/flux-react";
/* eslint-disable-next-line no-restricted-imports */
import { useLingui } from "@lingui/react";
import debounce from "lodash/debounce";
import { t } from "@lingui/macro";
import { getEndpointURL } from "@gocardless/api/dashboard/common/endpoints";
import { NO_REDIRECT_TO_404 } from "src/common/api";
import api from "@gocardless/api/utils/api";
import { UseFormReturn } from "react-hook-form";

import { Route, routerPush } from "../../common/routing";

import { SearchSuggestion, findIdMatch } from "./utils";
import SearchSuggestions from "./SearchSuggestions";

const searchSubmit = (data: EXTERNAL): void => {
  const searchText = data.search?.trim();

  searchText &&
    routerPush({
      route: Route.Search,
      queryParams: {
        query: searchText,
      },
    });
};

interface SearchFormProps {
  form: UseFormReturn<{
    search: string;
  }>;
  hasAccessory?: boolean;
  onBlur: () => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchForm: React.FC<SearchFormProps> = ({
  form,
  hasAccessory = true,
  onBlur,
  setOpen,
}) => {
  const searchRef = useRef<HTMLDivElement>(null);
  const { i18n } = useLingui();
  const { handleSubmit, register, setFocus, watch } = form;

  const placeholder = i18n._(t({ id: "Search", message: "Search" }));
  const searchText = watch("search");
  const [suggestions, setSuggestions] = useState<Array<SearchSuggestion>>();
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);

  const getSearchSuggestions = (value: string) => {
    const match = findIdMatch(value);
    if (match) {
      fetchResource(match);
    } else {
      setSuggestions([]);
    }
  };

  useEffect(() => {
    setShowSuggestions(!!searchText);
    if (searchText) {
      handleOnChange(searchText);
    }
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    setShowSuggestions(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchResource = (match: SearchSuggestion) =>
    api.API.get(getEndpointURL(match.endpoint, { [match.param]: match.id }), {
      headers: { [NO_REDIRECT_TO_404]: "true" },
    })
      .then((resp) => {
        resp.json();
        setSuggestions([match]);
      })
      .catch(() => {
        setSuggestions([]);
      });

  useEffect(() => {
    if (!searchText) {
      setFocus("search");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFocus]);

  const debouncedSetSearchTerm = debounce((text: string) => {
    getSearchSuggestions(text);
  }, 500);

  const handleOnChange = (text: string) => {
    debouncedSetSearchTerm(text);
  };

  return (
    <Box
      ref={searchRef}
      css={{ position: "relative" }}
      width="100%"
      gutterH={[0, 1, 0, 0]}
      maxWidth={["100%", 320, null, null]}
    >
      <Form onSubmit={handleSubmit(searchSubmit)}>
        <Input
          type="search"
          data-testid="searchInput"
          {...register("search")}
          name="search"
          placeholder={placeholder}
          onBlur={(event) => {
            if (!searchRef.current?.contains(event.relatedTarget)) {
              setShowSuggestions(false);
            }
            if (!searchText) {
              onBlur();
            }
          }}
          onFocus={() => {
            setShowSuggestions(true);
          }}
          leftAccessory={
            hasAccessory ? (
              <Icon
                name={Glyph.Search}
                color={ColorPreset.IconOnLight_01}
                size="14px"
              />
            ) : null
          }
          rightAccessory={
            hasAccessory ? (
              <PlainButton
                css={{ marginRight: "12px", display: "flex" }}
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Icon
                  name={Glyph.Close}
                  color={ColorPreset.IconOnLight_01}
                  size="14px"
                />
              </PlainButton>
            ) : null
          }
          gutter={InputGutter.Sm}
        />
        <SearchSuggestions
          setShowSuggestions={setShowSuggestions}
          showSuggestions={showSuggestions}
          searchText={searchText}
          suggestions={suggestions}
        />
      </Form>
    </Box>
  );
};

export default SearchForm;
