import { Breakpoint } from "@gocardless/flux-react";
import { useIsBreakpoint } from "src/hooks/useIsBreakpoint";

import {
  MobileOrganisationSwitcher,
  OrganisationSwitcher,
} from "./OrganisationSwitcher";

export const ResponsiveOrganisationSwitcher: React.FC = () => {
  const isSmallBreakpoint = useIsBreakpoint({ max: Breakpoint.Sm });

  return isSmallBreakpoint ? (
    <MobileOrganisationSwitcher />
  ) : (
    <OrganisationSwitcher />
  );
};
